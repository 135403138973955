<template>
  <div class="home">

    <v-snackbar
        top
        multi-line
        :color="snackbar.color"
        v-model="snackbar.visible"
    >
      {{this.snackbar.text}}
    </v-snackbar>

    <h1>Project Code Search</h1>
    <div class="d-sr-only" v-show="srIsLoading">
      <span role="alert" aria-live="assertive">Loading. Please wait.</span>
    </div>

    <v-row>
      <v-col cols="12" md="4">
        <v-select
          v-model="evaluationSearch.evaluationType"
          :items="evaluationTypes"
          item-text="evaluation type"
          label="Evaluation Type"
          item-value="evaluation type"
          clearable
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="evaluationSearch.evaluationNumber"
          item-text="evaluation number"
          label="Evaluation Number"
          item-value="evaluation number"
          clearable
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="evaluationSearch.department"
          :items="departments"
          item-text="department"
          label="Department"
          item-value="department"
          clearable
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <v-select
          v-model="evaluationSearch.status"
          :items="statuses"
          item-text="status"
          label="Status"
          item-value="status"
          clearable
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="evaluationSearch.providerName"
          item-text="evaluation/project name"
          label="Evaluation/Project Name"
          item-value="evaluation/project name"
          clearable
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="evaluationSearch.jiraIssueKey"
          item-text="jira key"
          label="JIRA Key"
          item-value="jira key"
          clearable
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="evaluationSearch.providerNumber"
          item-text="provider number"
          label="Provider Number"
          item-value="provider number"
          clearable
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
            v-model="evaluationSearch.surveyType"
            item-text="survey type id"
            label="Survey Type ID"
            item-value="survey type id"
            clearable
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3"/>
      <v-col cols="12" md="3">
        <v-btn
          large
          color="primary"
          @click="loadTable">
        Search
        </v-btn>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn
          large
          color="primary"
          @click="$router.push('/evaluation-form')">
        Go to Create Page
        </v-btn>
      </v-col>
      <v-col cols="12" md="3"/>
    </v-row>

    <v-data-table
      style="margin-top: 60px"
      :headers="headers"
      :items="evaluations"
      :search="search"
      item-key="uniqueName"
      :server-items-length="totalElements"
      :loading="isLoading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      caption="Project Codes"
    >
      <template v-slot:header="{ headers }">
        <v-btn
          v-for="(item, index) in headers"
          :key="index"
          text
          small
          color="primary"
        />
      </template>

      <template v-slot:header>
        <v-dialog
          persistent
          v-model="confirmDelete"
          max-width="400px"
        >
          <v-form v-model="valid">
            <v-card>
              <v-card-title>
                <span class="text-h5">Confirm Delete</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    Are you sure you want to delete ?
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="deleteEvaluation"
                  :disabled="!valid"
                >
                  Yes
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="confirmDelete = false"
                >
                  No
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </template>

      <template v-slot:top>
        <v-dialog
          persistent
          v-model="updateDialog"
          max-width="1440px"
        >
          <v-form v-model="valid">
            <v-card>
              <v-card-title>
                <span class="text-h5">Update Evaluation</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="editedItem.surveyType"
                        :items="filterSurveyTypes(editedItem.evaluationType)"
                        label="Survey Type"
                        clearable
                        item-value="surveyTypeId"
                        class="required"
                        required
                        :rules="[requiredRule[0]]"
                      >
                        <template slot="selection" slot-scope="data">
                          {{ data.item.surveyTypeId }} - {{ data.item.surveyType }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ data.item.surveyTypeId }} - {{ data.item.surveyType }}
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                          v-model="editedItem.evaluationNumber"
                          label="Evaluation Number"
                          class="required"
                          disabled
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                          v-model="editedItem.providerNumber"
                          label="Provider Number"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                          v-model="editedItem.department"
                          :items="departments"
                          item-text="department"
                          label="Department"
                          clearable
                          item-value="department"
                          class="required"
                          required
                          :rules="[requiredRule[0]]"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                          v-model="editedItem.evaluationType"
                          :items="evaluationTypes"
                          item-text="evaluation"
                          label="Evaluation Type"
                          item-value="evaluation"
                          clearable
                          class="required"
                          required
                          :rules="[requiredRule[0]]"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                          v-model="editedItem.providerName"
                          label="Provider Name"
                          class="required"
                          required
                          :rules="[requiredRule[0]]"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                          v-model="editedItem.providerCounty"
                          label="Provider County"
                          class="required"
                          required
                          :rules="[requiredRule[0]]"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                          v-model="editedItem.providerType"
                          label="Provider Type"
                          class="required"
                          required
                          :rules="[requiredRule[0]]"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                          v-model="editedItem.fiscalYear"
                          label="Fiscal Year"
                          class="required"
                          required
                          :rules="[requiredRule[0]]"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                          v-model="editedItem.status"
                          :items="statuses"
                          item-text="status"
                          label="Status"
                          clearable
                          item-value="status"
                          class="required"
                          required
                          :rules="[requiredRule[0]]"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-btn
                          @click="deleteItem"
                          color="#b60000"
                          class="white--text"
                      >
                        Delete
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer/>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  :disabled="!valid"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>

        <v-dialog
            persistent
            transition="dialog-bottom-transition"
            v-model="moreInfoDialog"
            style="max-width: 90%"
        >
          <v-card>
            <v-layout>
              <v-navigation-drawer
                 class="deep-purple accent-4"
                 style="height: 100%"
                 dark
                 permanent>
                <v-list>
                  <v-list-item @click="moreInfoOption='ExitInfo'">
                    Exiting Information
                  </v-list-item>
                  <v-list-item @click="moreInfoOption=''">
                    List Of Penalties
                  </v-list-item>
                  <v-list-item @click="moreInfoOption=''">
                    Conditional
                  </v-list-item>
                </v-list>
              </v-navigation-drawer>
              <v-main v-if="moreInfoOption" style="max-width: 85%">
                <ExitingInfo v-if="moreInfoOption === 'ExitInfo'"></ExitingInfo>
              </v-main>
            </v-layout>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          class="mr-2"
          @click="editItem(item)"
          aria-label="Edit"
          aria-hidden="false"
        >
          mdi-pencil
        </v-icon>
      </template>

      <!-- TODO: disabled until ready for production
      <template v-slot:item.options="{ item }">
        <v-btn
            v-if="hasDetails(item.evaluationNumber)"
            @click="$router.push({name: 'Details', params: {evaluationId: item.id}})"
            aria-label="Details"
            aria-hidden="false">
          details
        </v-btn>
      </template>
      -->
    </v-data-table>
  </div>
</template>

<script>
import {EvaluationType} from "@/shared/model/EvaluationType";
import {ProviderType} from "@/shared/model/ProviderType";
import {Status} from "@/shared/model/Status";
import {Department} from "@/shared/model/Department";
import {rules} from "@/shared/model/Rules";
import {evalApi} from "@/plugins/axios";
import ExitingInfo from "@/views/ExitingAndEnforcement/ExitingInfo";

export default {
  components: {ExitingInfo},

  watch: {
    options : {
      deep: true
    }
  },

  created() {
    this.loadTable()
    this.loadSurveyTypes()
  },

  mounted() {
    const self = this
    document.addEventListener('keypress', function(ev) {
      if (ev.key === 'Enter' && self.$route.name === "EvaluationList") {
        self.loadTable()
      }
    })
    this.srOnlyLoad()
  },

  data() {
    return {
      maskText: "",
      search: "",
      valid: true,
      evaluationSearch: [],
      evaluationTypes: EvaluationType.values.sort(),
      providerTypes: ProviderType.values,
      surveyTypes: [],
      //surveyTypes: Array<SurveyType>: SurveyType.values;
      statuses: Status.values,
      //fiscalYears: new FiscalYear().values;
      departments: Department.values.sort(),
      requiredRule: rules,
      evaluations: [],
      srCountdown: 4,
      totalElements: 0,
      updateDialog: false,
      moreInfoDialog: false,
      moreInfoOption: null,
      confirmDelete: false,
      isLoading: true,
      srIsLoading: true,
      editedIndex: -1,
      snackbar: {
        visible: false,
        color: "success",
        text: ""
      },

      options: {
        itemsPerPage: 15,
        page: 1,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
      },

      editedItem: {
        surveyType: "",
        evaluationNumber: "",
        providerNumber: "",
        department: "",
        evaluationType: "",
        providerName: "",
        providerCounty: "",
        providerType: "",
        fiscalYear: "",
        status: "",
        jobName: ""
      },

      defaultItem: {
        surveyType: "",
        evaluationNumber: "",
        providerNumber: "",
        department: "",
        evaluationType: "",
        providerName: "",
        providerCounty: "",
        providerType: "",
        fiscalYear: "",
        status: "",
        jobName: ""
      },

      headers: [
        {text: "Edit", value: "actions", sortable: false},
        {text: "Evaluation Type", value: "evaluationType", sortable: true},
        {text: "Provider Number", value: "providerNumber", sortable: true},
        {text: "Evaluation Number", value: "evaluationNumber", sortable: true},
        {text: "Department", value: "department", sortable: true},
        {text: "Evaluation/Project Name", value: "providerName", sortable: true},
        {text: "Survey Type ID", value: "surveyType", sortable: true},
        {text: "JIRA Key", value: "jiraIssueKey", sortable: true},
        //todo: disabled until ready for production {text: "Options", value: "options", sortable: false}
      ]
    }
  },

  methods: {
    loadTable() {
      this.isLoading = true
      evalApi.post(`/evaluationsImport/search-for-evaluations`, {
        providerType: this.evaluationSearch.providerType,
        evaluationType: this.evaluationSearch.evaluationType,
        evaluationNumber: this.evaluationSearch.evaluationNumber,
        status: this.evaluationSearch.status,
        providerNumber: this.evaluationSearch.providerNumber,
        providerName: this.evaluationSearch.providerName,
        department: this.evaluationSearch.department,
        jiraIssueKey: this.evaluationSearch.jiraIssueKey,
        surveyType: this.evaluationSearch.surveyType,
        page: this.options.page,
        size: this.options.itemsPerPage,
      }).then((res) => {
        this.evaluations = res.data.content
        this.totalElements = res.data.totalSize
        this.isLoading = false
      })
    },

    loadSurveyTypes() {
      evalApi.get('/surveyType/all')
          .then((res) => {
            this.surveyTypes = res.data;
          })
    },

    srOnlyLoad() {
      if(this.isLoading && this.srCountdown > 0){
        setTimeout(() => {
          this.srCountdown -= 1
          this.srOnlyLoad()
        }, 1000)

      }
      else if(this.isLoading) {
        this.srCountdown = 4
        this.srIsLoading = !this.srIsLoading
        this.srOnlyLoad()
      }
      else {
        this.srIsLoading = false
      }
    },

    editItem(item) {
      this.editedIndex = this.evaluations.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.updateDialog = true
    },

    moreInfoItem(item) {
      this.editedIndex = this.evaluations.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.moreInfoDialog = true
    },

    deleteItem() {
      console.log("deleted successfully")
      this.confirmDelete = true
    },

    save() {
      this.editedItem.jobName = "EvaluationsImport"
      evalApi.post("/evaluationsImport/dataImport", {
        evaluationList: [this.editedItem]
      }).then(() => {
        this.snackbar.color = "success"
        this.snackbar.text = "Evaluation has been updated."
        this.snackbar.visible = true
        this.loadTable()
      }).catch(error => {
        this.snackbar.color = "error"
        this.snackbar.text = "An error occurred updating the evaluation: >>>> " + error
        this.snackbar.visible = true
      })
      this.close()

    },

    close() {
      this.updateDialog = false
      this.moreInfoDialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },

    deleteEvaluation() {
      evalApi.get("/jira/delete/"+this.editedItem.evaluationNumber).then(res => {
        this.confirmDelete = false
        this.close()
        this.loadTable()
        //window.location.reload();
        alert(res.data.message + " " + res.data.detail)
      }).catch(() => {
        alert('An error occurred deleting the evaluation>>>> ${error}')
      })
    },

    filterSurveyTypes(flag) {
        const filteredSurveys = [];
        for (let i = 0; i < this.surveyTypes.length; i++) {
            if(this.surveyTypes[i].commonProjectFlag == flag) {
                filteredSurveys.push(this.surveyTypes[i]);
            }
        }
        return filteredSurveys;
    },

    hasDetails(evalNum) {
      const subStr = evalNum.substring(0,2);
      if(subStr === "HL" || subStr === "SL") {
        return true;
      }
      return false;
    }
  }
}
</script>

<style>
.v-input__control {
  padding: 10px 10px 10px 10px;
}
</style>
